<template>
  <button
    class="color-button border text-left flex flex-col justify-between"
    :class="{ 'border-black': active }"
    @click="onClick"
  >
    <div class="px-3 py-1 w-full">
      <h1 class="text-nowrap">
        <div class="flex justify-between items-center gap-3">
          <div>{{ color.name }}</div>
          <div v-if="color.is_transparent" class="transparent-mark"></div>
        </div>
      </h1>
      <h2 class="text-nowrap text-sm text-gray-800">{{ color.description }}</h2>
    </div>
    <div class="w-full">
      <div
        class="w-full h-8 flex items-center justify-center font-mono px-3 gap-3"
        :style="colorStyle"
      >
        <div class="flex-1 flex justify-center">
          <div>#{{ color.hex }}</div>
        </div>
        <div
          v-if="!hideRecipeButton"
          :class="{ 'invisible': !color.has_recipe }"
        >
          <button @click="onRecipeShow" class="flex items-center">
            <i class="material-icons text-xl">list_alt</i>
          </button>
        </div>
        <div v-if="!color.hex.startsWith('000000')">
          <button @click="onColorCopy" class="flex items-center">
            <i class="material-icons text-xl">content_paste</i>
          </button>
        </div>
      </div>
    </div>
    <recipe-modal v-if="recipe.showModal" :data="recipe" />
  </button>
</template>

<script>
import { isColorDark } from '@/helpers';

const RecipeModal = () => import('@/components/design/RecipeModal.vue');

export default {
  name: 'ColorButton',
  components: {
    RecipeModal,
  },
  props: {
    active: Boolean,
    color: Object,
    hideRecipeButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colorStyle() {
      const colorStyle = {
        'background-color': `#${this.color.hex}`,
        color: isColorDark(this.color.hex) ? 'white' : 'black',
      };
      return colorStyle;
    },
  },
  data() {
    return {
      recipe: {
        showModal: false,
        id: null,
      },
    };
  },
  methods: {
    onClick(event) {
      this.$emit('click', event);
    },
    onRecipeShow(event) {
      event.stopPropagation();
      this.recipe.color = this.color;
      this.recipe.showModal = true;
    },
    onColorCopy(event) {
      event.stopPropagation();
      try {
        const textarea = document.createElement('textarea');
        textarea.textContent = this.color.hex;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        this.$bvToast.toast(`Copied '${this.color.hex}' to clipboard!`, {
          title: 'Success',
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-bottom-right',
          autoHideDelay: 2000,
        });
      } catch (err) {
        this.$bvToast.toast('Failed to copy to clipboard!', {
          title: 'Error',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-bottom-right',
          autoHideDelay: 2000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .color-button {
    width: 100%;
    min-width: 100px;
    height: 80px;
  }

  .color-button:focus {
    outline: 0;
  }

  .transparent-mark {
    width: 12px;
    height: 12px;
    background: linear-gradient(-45deg, black, black 49%, rgba(0, 0, 0, .1) 50%);
    border-radius: 50%;
  }
</style>
